.zoom-buttons {
    position: absolute;
    top: 5px; /* Position from the top of the container */
    right: 10px; /* Position from the right of the container */
    z-index: 999; /* Ensure the buttons are on top */
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    @media (max-width: 540px) {
      right: 3px;
      top: 8px;
    }
  }
  
  .zoom-button {
    background-color: rgba(0, 123, 255, 0.35); /* Semi-transparent white background */
    color: rgb(6, 50, 94); /* Button text color */
    border: 200px;
    border-color: black;
    border-radius: 50%; /* Rounded shape */
    width: 35px;
    height: 35px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin: 5px; /* Add some space between buttons */
    position: relative;
    font-family: "Font Awesome 5 Free";   
    @media (max-width: 540px) {
      right: 3px;
      margin: 2px;
    }
  }

  .zoom-button.zoom-in::before {
    content: "\f00e";
  }

  .zoom-button.zoom-out::before {
    content: "\f010";
  }

  .zoom-button.zoom-reset::before {
    content: "\f422";
  }

  .zoom-button:hover {
    background-color: #0056b3;
    color: white; /* Button text color */
  }
  
  .zoom-button span {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px; /* Adjust vertical alignment */
  }

  
.controls-menu {
    position: fixed;
    top: 15%;
    left: -100%; /* Off-screen initially, adjust according to menu width */
    height: auto;
    transition: right 0.3s ease; /* Smooth sliding transition */
    z-index: 990; /* Ensure it overlays other elements */
  }
  
.controls-menu.show-menu {
  left: 0; /* Show the menu by sliding it into view */
  display: block;
}

.show-menu-button {
  margin: 3px;
  padding: 3px 7px;
  font-size: 14px;
}

.close-menu-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000; /* Ensure it's above the sliding menu */
}

.controls-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(221, 215, 215);
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 5px;
  gap: 5px;
  @media (max-width: 400px) {
    gap: 0px;
  }
}

.special-controls-wrapper {
  white-space: nowrap; /* Prevents text from wrapping */
  align-items: center;
  background-color: rgb(221, 215, 215);
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  gap: 10px;
  padding: 10px;
  @media (min-width: 1185px) and (max-width: 1365px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust columns dynamically */
      grid-template-rows: 1fr; /* Force only one row */
      gap: 5px;
  }
}

.desktop-grid {
  margin: 3px;
  display: grid;
  grid-auto-flow: column; /* Force items to flow in a single row */
  grid-template-columns: auto;
  @media (min-width: 1365px) {
    grid-template-rows: [row1-start] 33% [row1-end row2-start] 33% [row2-end];
    align-content: center;
  }
}

.mobile-grid {
    display: grid;
    grid-template-columns: [column1-start] 50% [column1-end column2-start] 50% [column2-end]; 
}

.special-button-container {
  display: grid;
  gap: 10px;
  width: 100%;
}

.button-toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1px; /* Adds space between the two containers */
  flex-wrap: nowrap;
  @media (max-width: 1365px) {
      flex-direction: column;
  }
}

@media (min-width: 1185px) {
  .controls-wrapper {
    flex-direction: row; /* Align controls and buttons side-by-side */
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 5px;
  }
}

@media (max-width: 1185px) {
  .controls-wrapper {
    position: fixed;
    flex-direction: row;
    bottom: 0;
    padding: 3px;
    width: 100%;
    z-index: 9999;
  }
}



@media (max-width: 1185px) {
  .button-toolbar {
    flex-direction: column;
    align-items: center;
  }

  .special-button-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.special-controls-wrapper.row {
  flex-direction: row;
  align-items: center;
}

.button-container {
  padding: 10px 15px;
  border: 1px solid #007bff; /* Blue border */
  border-radius: 10px;
  background-color: #f8f9fa; /* Light gray background */
  text-align: center;
  box-shadow: 12px 3px 12px rgba(0, 0, 0, 0.1);
}

.button-container-row {
  display: flex;
  justify-content: center;
}

@media (max-width: 1185px) {
  .button-container {
    padding: 2px;
  }
}

.control-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  margin: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  
  /* Media query for smaller screens (e.g., iPhone) */
  @media (max-width: 540px) {
    font-size: 14px;
    margin: 5px 10px;
  }
}

.arrow {
  @media (max-width: 540px) {
    font-size: 6px;
    padding: 15px 8px;
    margin: 2px;
  }
}
.control-button:hover {
  background-color: #0056b3;
}

.control-button:active {
  transform: translateY(2px); /* Moves the button down */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Slightly reduce shadow */
}

.control-button svg {
  width: 14px;
  height: 14px;
  fill: white;
  @media (max-width: 400px) {
    width: 12px;
  }
}

.time-period-label {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: #007bff; /* Highlighted text */
  margin-bottom: 5px;
  padding: 2px;
  border-radius: 5px;
}


.landscape {

  .controls-wrapper {
    right: -100%;
    display: grid;
    grid-template-columns: [column1-start] 50% [column1-end column2-start] 50% [column2-end];
    margin: 0px;
    padding: 5px;
    gap: 1px;
    width: 200px;
  }

  .controls-wrapper.show-menu {
    right: 0; 
  }

  .special-controls-wrapper {
    display: grid;
    grid-template-columns: [column1-start] 25% [column1-end column2-start] 25% [column2-end column3-start] 25% [column3-end column4-start] 25% [column4-end];
    gap: 3px;
  }

  .control-button
  {
    font-size: 12px;
  }

  .day-menu-button
  {
    padding: 20px;
    border-radius: 5px;
    border-color: #007bff;
    color: #007bff;
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 3001;
  }

  .arrow {
    padding: 15px 10px;
  }
  .time-period-label {
    padding: 1px;
    margin: 1px;
    font-size: 10px;
  }
}

