.dialog-right-overlay {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px; /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 5px;
  z-index: 1001 ;
}

.dialog-left-overlay {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 330px; /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 5px;
  z-index: 1001 ;
}

.date-right-overlay {
  position: fixed;
  top: 40px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 1px;
  z-index: 1001 ;
}

.dialog {
  background-color: white;
  padding: 15px;
  padding-top: 10px;
}

.dialog textarea, input {
  width: 100%;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.dialog textarea {
  height: 150px; /* Adjust height as needed */
}

.dialog button {
  display: block;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 5px;
}

.prompt-text {
  font-size: 11px;
}

.about-text {
  font-size: 12px;  
  @media (max-width: 540px) {
    font-size: 10px;
  }
}

.datepicker-container {
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 1101; 
  @media (min-width: 1185px) {
    top: 25%;
    left: 3%;
  }
    
  }

.debugDisplay {
  background-color: rgb(253, 176, 176);
  padding: 5px;
}

.landscape {
  .datepicker-container {
    top: 15%;
    left: 15%;
    z-index: 2101;
  }

  .dialog {
    height: 300px;
    overflow-y: auto;
  }
}