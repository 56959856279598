.header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 5px;
}

/* Responsive layout for small screens */
@media screen and (max-width: 1185px) {
  .second-controls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white; /* Adjust color if needed */
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    justify-content: center;
    z-index: 1000;
  }

  /* Ensure .header adapts to the smaller layout */
  .header {
    flex-direction: column;
    align-items: center;
  }
}

.header-container {
    background-color: #fdcf1a;
    display: flex;
    /* justify-content: space-between; /* Align title and feedback link at opposite ends */
    margin: 5px;
    flex-shrink: 0; /* Header should not shrink */
    border-radius: 30px;
    @media (max-width: 1185px) {
      border-radius: 10px; 
    }
  }
  
.date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 10px;
  background-color: #fdcf1a;
  border-radius: 20px;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
  @media (min-width: 1185px) {
      min-width: 260px;
  }
  @media (max-width: 1185px) {
    width: 100%;
    border-radius: 10px;
    display: flex;
    z-index: 2001;
  }
}

.date-strap {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
}


@media (min-width: 1185px) {

  .date-strap {
    text-align: center;
    padding: 5px 55px;
  }

  .date-strap span {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
    @media (max-width: 1185px) {
      font-size: 20px;
      padding: 5px; 
    }
  }
}

.feedback-link {
  font-size: 12px;
  font-weight: bold;
  text-decoration: solid;
  color: blue;
  text-align: center;
  cursor: pointer; 
  padding: 3px;   
  /* Media query for smaller screens (e.g., iPhone) */
  @media (max-width: 1185px) {
    font-size: 12px;
  }
}

.feedback-link:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  transform: translateY(-2px); /* Lift effect */
}

.feedback-link:active {
  background-color: #d6d6d6; /* Darker gray for active state */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
  transform: translateY(0); /* Button returns to its original position */
}

.icon-button {
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

.icon-button:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  transform: translateY(-2px); /* Lift effect */
}

.icon-button:active {
  background-color: #d6d6d6; /* Darker gray for active state */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
  transform: translateY(0); /* Button returns to its original position */
}

@media (max-width: 1185px) {
    .feedback-link {
      font-size: 16px; /* Slightly smaller font for mobile */
      width: calc(100% - 40px); /* Full width with margins */
      max-width: 400px; 
    }
}

.links-container {
  display: flex;
  flex-direction: column; 
  margin: 10px;
}

.links-container-row {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 6px;
  align-content: center;
}

.links-container a {
  margin-bottom: 2px; /* Add some spacing between links */
}

.links-container-row:last-child {
  margin-top: auto; /* Pushes it to the bottom */
}

.copy-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center vertically */
  padding: 5px;
  margin: 5px;
  font-size: 16px;  /* Media query for smaller screens (e.g., iPhone) */
  @media (max-width: 1185px) {
    font-size: 16px; /* adjust font size for smaller screens */
    padding: 8px;
    padding-right: 8px;
    right: 0;
  }
}

.copy-link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.cal-link {
  filter: invert(0); /* Invert the colors */
}

.cal-link.active {
  opacity: 0.2;
}

.cal-link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.small-date-strap {
  position: absolute;
  display: flex;
  top: 20px;
  left: 10px;
  background-color:#fdcf1a ;
  color: black;
  border-radius: 8px;
  z-index: 991;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
  align-items: center;
}

.small-date-strap span {
  padding: 8px;
}

.date-strap h1 {
  font-weight: bold;
  font-size: 24px;
  text-align: center;  
  @media (max-width: 1185px) {
    font-size: 18px;
  }
}

.fade-text {
  font-size: 12pt;
  font-weight: bold;
  color: white;
  background-color: red;
  opacity: 1;
  animation: revealText 1.25s forwards;
  transition: opacity 0.5s ease-in-out;
  margin: 5px;
  padding: 3px;
  position: absolute;
  top: 8px;
  left: 100px;  
  @media (max-width: 1185px) {
    font-size: 10pt;
    left: 75px; 
  }
}

.fade-text.hidden {
  opacity: 0;
}